import mergeWith from 'lodash/mergeWith';
import t from 'react-translate';
import { replaceArrays } from 'shared/lodash-utils';
import prodPathReplace from 'shared/prod-path-rewrite';
import store from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';

/* @ngInject */
export default function InstitutionsManager(
  $q,
  CourseModel,
  CourseRolesManager,
  CoursesResource,
  InstitutionAdminModel,
  InstitutionAdminsResource,
  InstitutionsResource,
  Upload,
  UserManagementResources,
  _,
  humps,
  $timeout,
) {
  class InstitutionModel {
    constructor(attributes) {
      _.extend(this, attributes);
    }

    updateFromReact(newModelData) {
      $timeout(() => {
        mergeWith(this, newModelData, replaceArrays);
      });
    }
  }

  const INTEGRATION_TYPES = {
    ZOOM_USER_LEVEL: 'zoom_oauth',
    ZOOM_ACCOUNT_LEVEL: 'zoom_account_oauth',
    MS_TEAMS_USER_LEVEL: 'microsoft_graph_oauth',
    MS_CALENDAR_USER_LEVEL: 'microsoft_graph_calendar_oauth',
  };

  const _this = {
    initialized: null,
    requestCurrentInstitutionPromise: null,
    institution: null,
    setInstitution,
    initialize,
    requestCourses,
    requestCoursesAndCloningRequests,
    requestInstitutionAdmins,
    saveInstitutionAdmin,
    deleteInstitutionAdmin,
    updateBrand,
    getBasicWalledGardenInfo,
    updateFavicon,
    backupProviderData,
    restoreProviderData,
    saveProviders,
    disableWelcomeEmail,
    enableWelcomeEmail,
    setBasicSSOInfoEdit,
    setSSOWithoutEmail,
    saveTos,
    userManagementResources: UserManagementResources,
    searchOrgUsers,
    orgDashboardUserSearch,
    toggleOrgSearchAllUsers,
    toggleAvailabilityFileDownload,
    toggleLearnersSelfUnenrollment,
    toggleGenerativeAiCapabilities,
    toggleAppPromotion,
    getLiveSessionSettings,
    saveLiveSessionSettings,
    disableLiveSessionSettings,
    saveDegreedSettings,
    authenticateMicrosoftCalendarOauth,
    disableCalendarSettings,
    saveCapabilityTags,
    getUserExportDetails,
    exportData,
    updateInstitutionAdminRole,
    toggleMsTeamsBotIntegrations,
    saveLmsAuthentication,
    saveSabaDomain,
    disableLmsIntegration,
    hasIntegration,
    validateLmsActivity,
    INTEGRATION_TYPES,
  };

  function setInstitution(institution) {
    _this.institution = new InstitutionModel(institution);
  }

  function initialize(id) {
    if (!id) {
      throw new Error('Missing Required Institution Id');
    }

    if (_this.requestCurrentInstitutionPromise) {
      return _this.requestCurrentInstitutionPromise;
    }

    if (id === _this.initialized) {
      return Promise.resolve({
        result: _this.institution,
      });
    }

    _this.requestCurrentInstitutionPromise = InstitutionsResource.get(
      { id },
      (resource) => {
        _this.institution = new InstitutionModel(resource.result);
        _this.initialized = _this.institution.id;

        if (_this.institution.nickname) {
          _this.institution.lowercaseNickname = _this.institution.nickname.toLowerCase();
        }

        if (_this.institution.favicon) {
          _this.institution.favicon = {
            type: 'image',
            url: resource.result.favicon,
          };
          _this.updateFavicon(_this.institution.favicon);
        }

        if (_this.institution.brandColor === '') {
          _this.institution.brandColor = '#acb5c2';
        }

        if (!_this.institution.emailHeaderStyle) _this.institution.emailHeaderStyle = '';

        return CourseRolesManager.loadCourseRoles(_this.institution.id);
      },
    ).$promise;

    _this.requestCurrentInstitutionPromise.finally(() => {
      _this.requestCurrentInstitutionPromise = null;
    });

    return _this.requestCurrentInstitutionPromise;
  }

  function requestCourses(parentOnly) {
    if (!_this.institution || !_this.institution.id) {
      throw new Error('Missing Required Institution Data');
    }

    return CoursesResource.query(
      { institutionId: _this.institution.id, parents: !!parentOnly },
      (resource) => {
        _this.institution.courses = _.map(resource.result, (c) => {
          const courseModel = new CourseModel(c);
          courseModel.institution = _.pick(_this.institution, 'id', 'host', 'isPremium', 'isInstitutionalAdmin', 'adminRole');
          return courseModel;
        });
        constructCoursesHash();
      },
    ).$promise;
  }

  function requestCoursesAndCloningRequests() {
    if (!_this.institution || !_this.institution.id) {
      throw new Error('Missing Required Institution Data');
    }

    const coursesPromise = CoursesResource.query({ institutionId: _this.institution.id }).$promise;
    const cloningRequestsPromise = InstitutionsResource.getCloningRequests({ id: _this.institution.id }).$promise;

    return $q.all([coursesPromise, cloningRequestsPromise]).then((resources) => {
      const courses = resources[0].result;
      const cloningRequests = resources[1].result;

      _this.institution.courses = _.map(courses, (c) => {
        const courseModel = new CourseModel(c);
        courseModel.institution = _.pick(_this.institution, 'id', 'host', 'isPremium', 'isInstitutionalAdmin', 'adminRole');
        return courseModel;
      });
      constructCoursesHash();

      _.each(cloningRequests, (cloningRequest) => {
        const mockCourse = _.clone(_this.institution.coursesHash[cloningRequest.ownerId]);

        mockCourse.catalogId = cloningRequest.nickname;
        mockCourse.releaseDate = cloningRequest.newDate;
        mockCourse.duplicationInProgress = true;

        _this.institution.courses.push(mockCourse);
      });
    });
  }

  function requestInstitutionAdmins() {
    return InstitutionAdminsResource.query(
      { institutionId: _this.institution.id },
      (resource) => {
        _this.institution.institutionAdmins = [];
        _.each(resource.result, (institutionAdmin) => {
          _this.institution.institutionAdmins.push(new InstitutionAdminModel(institutionAdmin));
        });
      },
    ).$promise;
  }

  function enableWelcomeEmail() {
    return InstitutionsResource.enableWelcomeEmail({ id: _this.institution.id }).$promise.then((resource) => {
      _.extend(_this.institution, resource.result);
    });
  }

  function disableWelcomeEmail() {
    return InstitutionsResource.disableWelcomeEmail({ id: _this.institution.id }).$promise.then((resource) => {
      _.extend(_this.institution, resource.result);
    });
  }

  function saveInstitutionAdmin(newInstitutionAdmin, adminRole) {
    return InstitutionAdminModel
      .save(_this.institution.id, newInstitutionAdmin, adminRole)
      .then((institutionAdmin) => {
        _this.institution.institutionAdmins.push(institutionAdmin);
      });
  }

  function updateInstitutionAdminRole(institutionAdminId, adminRole) {
    return InstitutionAdminModel
      .updateAdminRole(_this.institution.id, institutionAdminId, adminRole)
      .then(() => {
        _.extend(_.findWhere(_this.institution.institutionAdmins, { id: institutionAdminId }), {
          adminRole,
        });
      });
  }

  function deleteInstitutionAdmin(institutionAdmin) {
    return institutionAdmin.destroy().then(() => {
      _this.institution.institutionAdmins = _.without(_this.institution.institutionAdmins, institutionAdmin);
    });
  }


  function updateBrand() {
    let brandAttributes = _.pick(_this.institution, ['brandColor', 'name', 'brandBarFontColor']);
    brandAttributes = humps.decamelizeKeys(brandAttributes);
    if (_this.institution.newLogo) {
      brandAttributes.flyer_logo = _this.institution.newLogo;
    } else if (!_this.institution.logo) {
      brandAttributes.delete_logo = true;
    }

    if (_this.institution.favicon && !_this.institution.favicon.url) {
      brandAttributes.favicon = _this.institution.favicon;
    } else if (_this.institution.favicon === null) {
      brandAttributes.delete_favicon = true;
    }

    return Upload.upload({
      url: `institutions/${_this.institution.id}/update_brand`,
      method: 'PUT',
      fields: {
        institution: brandAttributes,
      },
    }).then((response) => {
      _this.institution.newLogo = null;
      _.extend(_this.institution, response.data.result);
      if (response.data.result.favicon) {
        _.extend(_this.institution, response.data.result, { favicon: { type: 'image', url: response.data.result.favicon } });
        _this.updateFavicon(_this.institution.favicon);
      }
    });
  }

  function getBasicWalledGardenInfo() {
    return InstitutionsResource.getBasicInfo().$promise.then((response) => {
      if (response?.result?.institutionList) {
        _this.updateFavicon({ url: response.result.institutionList.favicon }); // "institutionList" is misleading, there's only 1 and it's an object

        if (_this.institution) {
          _.extend(_this.institution, response.result.institutionList);
        } else {
          _this.institution = response.result.institutionList; // this should be ok as a temporary data object to read simple properties from
        }


        return response.result.institutionList;
      }

      return response.result;
    });
  }

  function updateFavicon(favicon) {
    const existingFavicon = $('head').find('link[rel="icon"]');
    let faviconUrl = prodPathReplace('images/favicon.ico');

    if (typeof favicon === 'string') {
      faviconUrl = favicon;
    } else if (favicon !== null && favicon.url) {
      faviconUrl = favicon.url;
    }

    existingFavicon.attr('href', faviconUrl);
  }

  function backupProviderData() {
    _.each(_this.providers, (provider) => {
      provider.backup = _.clone(provider);
    });
  }

  function restoreProviderData() {
    _.each(_this.providers, (provider) => {
      _.extend(provider, provider.backup);
    });
  }

  function saveProviders(providerDrafts) {
    return InstitutionsResource.updateProviders(
      { id: _this.institution.id },
      { providers: providerDrafts },
    ).$promise.then(() => {
      _this.institution.identityProviders.forEach((provider, i) => {
        _.extend(provider, providerDrafts[i]);
      });
    });
  }

  function saveTos() {
    return InstitutionsResource.saveTos({ id: _this.institution.id, institution: { tos: _this.institution.tos } }).$promise.then((resource) => {
      _.extend(_this.institution, resource.result);
    });
  }

  // Private Functions
  function constructCoursesHash() {
    _this.institution.coursesHash = {};
    _.each(_this.institution.courses, (course) => {
      _this.institution.coursesHash[course.id] = course;
    });
  }

  function setBasicSSOInfoEdit(enableSSOInfoEdit) {
    return InstitutionsResource.setAttributes(
      { id: _this.institution.id },
      // We must use a snake_case key name here because our camelCase interceptor attempts expanding 'SSO' to 's_s_o'
      { enable_basic_sso_info_edit: enableSSOInfoEdit },
    ).$promise.then(() => {
      _this.institution.enableBasicSsoInfoEdit = enableSSOInfoEdit;
    });
  }

  function setSSOWithoutEmail(value) {
    /**
     * updating allowsSsoWithoutEmail before API call to resolve the delay in switching enable/disable button
     * allowsSsoWithoutEmail will reset to previous value when API fail
     */
    _this.institution.allowsSsoWithoutEmail = value;
    return InstitutionsResource.setAttributes(
      { id: _this.institution.id },
      // We must use a snake_case key name here because our camelCase interceptor attempts expanding 'SSO' to 's_s_o'
      { allows_sso_without_email: value },
    ).$promise.catch(() => {
      _this.institution.allowsSsoWithoutEmail = !value;
      store.dispatch(addAlertMessage({ type: AlertMessageType.ERROR, header: t.FORM.OOPS(), message: t.FORM.ERROR_SOMETHING_WRONG() }));
    });
  }

  /**
     * Searches for users across the organization. Used from within the context
     * of a course, unlike orgDashboardUserSearch below. The result data set
     * is similar but this omits information like courses count used in the
     * org dashboard user search.
     * @param {string} catalogId
     * @param {number} pageIndex
     * @param {number} pageSize
     * @param {string} queryTerm
     */
  function searchOrgUsers(catalogId, pageIndex, pageSize, queryTerm, includeLearners, includeAdmins, includeMentors) {
    return _this.userManagementResources.orgSearch({
      catalogId,
    }, {
      page: pageIndex,
      pageSize,
      queryTerm,
      courseAdmins: includeAdmins,
      courseLearners: includeLearners,
      courseMentors: includeMentors,
    })
      .$promise.then((response) => _.map(response.result.users, (user) => user));
  }

  /**
     * See searchOrgUsers() above. Used in the org level dashboard user search
     * @param {number} pageIndex
     * @param {number} pageSize
     * @param {string} queryTerm
     */
  function orgDashboardUserSearch(pageIndex, pageSize, queryTerm) {
    return InstitutionsResource.searchUsers({
    }, {
      page: pageIndex,
      pageSize,
      queryTerm,
    })
      .$promise.then((response) => response.result);
  }

  /** Saves the selection for 'User Search Privacy Setting' */
  function toggleOrgSearchAllUsers() {
    InstitutionsResource.toggleSearchAllUsers({ id: _this.institution.id })
      .$promise.then((response) => {
        _this.institution.enableSearchAllUsers = response.result.enableSearchAllUsers;
      });
  }

  /** Defines if next updated files from course builders will be downloadable or not */
  function toggleAvailabilityFileDownload(allowFileDownloads) {
    return InstitutionsResource.toggleFileDownload({ id: _this.institution.id }, { allowFileDownloads })
      .$promise.then((response) => response.result);
  }

  /** Defines if next updated files from course builders will be downloadable or not */
  function toggleLearnersSelfUnenrollment(hasLearnersSelfUnenroll) {
    return InstitutionsResource.toggleSelfUnenrollment({ id: _this.institution.id }, { hasLearnersSelfUnenroll })
      .$promise.then((response) => response.result);
  }

  function toggleGenerativeAiCapabilities(hasAiComponentGeneration) {
    return InstitutionsResource.toggleGenerativeAiCapabilities({ id: _this.institution.id }, { hasAiComponentGeneration })
      .$promise.then((response) => response.result);
  }

  function toggleAppPromotion(appPromotionEnabled = false) {
    return InstitutionsResource.toggleAppPromotion({ id: _this.institution.id }, { appPromotionEnabled })
      .$promise.then((response) => response.result);
  }

  function saveLiveSessionSettings(apiKey, apiSecret, source) {
    return InstitutionsResource.saveLiveSessionSettings({ id: _this.institution.id }, { apiKey, apiSecret, source })
      .$promise.then((response) => response.result.isValid);
  }

  function disableLiveSessionSettings(source) {
    return InstitutionsResource.disableLiveSessionSettings({ id: _this.institution.id }, { source })
      .$promise.then((response) => response.result.isValid);
  }

  function saveDegreedSettings(apiKey, apiSecret, syncClosedRegistrations) {
    return InstitutionsResource.saveDegreedSettings({ id: _this.institution.id }, { apiKey, apiSecret, syncCloseEnrollment: syncClosedRegistrations })
      .$promise.then((response) => {
        _this.institution.degreedSettings = {
          apiKey,
          apiSecret,
          syncCloseEnrollment: syncClosedRegistrations,
        };

        return response.result.isValid;
      });
  }

  function authenticateMicrosoftCalendarOauth(apiKey, apiSecret, source) {
    return InstitutionsResource.authenticateMicrosoftCalendarOauth({ id: _this.institution.id })
      .$promise.then((response) => response.result.isValid);
  }

  function disableCalendarSettings(source) {
    return InstitutionsResource.disableCalendarSettings({ id: _this.institution.id }, { source })
      .$promise.then((response) => response.result.isValid);
  }

  function saveCapabilityTags(tags) {
    return InstitutionsResource.saveCapabilityTags({ id: _this.institution.id }, { degreedTags: tags })
      .$promise.then((response) => {
        _this.institution.degreedTags = response.result.degreedTags;

        return response;
      });
  }

  function getUserExportDetails(userId) {
    return InstitutionAdminModel.getUserExportDetails(_this.institution.id, userId);
  }

  function exportData(params) {
    return InstitutionAdminModel.exportData(_this.institution.id, params);
  }

  function toggleMsTeamsBotIntegrations(botSettingsEnabled) {
    return InstitutionsResource.toggleMsBot({ id: _this.institution.id }, { botSettingsEnabled })
      .$promise.then(response => response.result.isValid);
  }
  // LMS Configurations
  function saveLmsAuthentication(provider, clientId, clientSecret, endpoint, username, password, profileSettingId, learnUponModuleId) {
    const parameters = {
      lms: provider,
      clientId,
      clientSecret,
      clientEndpoint: endpoint,
    };
    if (username) parameters.username = username;
    if (password) parameters.password = password;
    if (profileSettingId) parameters.profileSettingId = profileSettingId.id;
    if (learnUponModuleId) {
      delete parameters.password;
      parameters.learnUponModuleId = learnUponModuleId;
    }
    return InstitutionsResource.saveLmsAuthentication({ id: _this.institution.id }, parameters).$promise;
  }

  function saveSabaDomain(domain, autoAssign, usernameType) {
    const parameters = {
      sabaUsernameType: usernameType.value,
      profileSettingId: usernameType.settingsId,
    };
    if (domain) {
      parameters.sabaDomainName = domain.name;
      parameters.sabaDomainId = domain.id;
    }
    if (autoAssign) parameters.sabaAutoAssign = autoAssign;
    return InstitutionsResource.saveLmsDomain({ id: _this.institution.id }, parameters).$promise;
  }

  function disableLmsIntegration() {
    return InstitutionsResource.deleteLmsIntegration({ id: _this.institution.id }).$promise;
  }

  function hasIntegration(sourceKey) {
    return (
      _this.institution?.liveSessionSettings
      && _this.institution.liveSessionSettings.source?.includes(sourceKey)
    );
  }

  function validateLmsActivity(payload) {
    return InstitutionsResource.validateLmsActivity({ id: _this.institution.id }, payload).$promise;
  }

  function getLiveSessionSettings() {
    if (_this.liveSessionSettingsRequestPromise) {
      return _this.liveSessionSettingsRequestPromise;
    }

    _this.liveSessionSettingsRequestPromise = new Promise((resolve, reject) => {
      if (_this.institution.liveSessionSettings) {
        resolve(_this.institution.liveSessionSettings);
        _this.liveSessionSettingsRequestPromise = null;
      }

      InstitutionsResource.getLiveSessionSettings({ id: _this.institution.id })
        .$promise
        .then((response) => {
          _this.institution.liveSessionSettings = response.result;
          resolve(response.result);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          _this.liveSessionSettingsRequestPromise = null;
        });
    });

    return _this.liveSessionSettingsRequestPromise;
  }

  return _this;
}
