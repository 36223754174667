import React, { useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import { without } from 'underscore';
import { useSelector } from 'react-redux';
import { camelCase } from 'lodash';

import { AngularContext, AngularServicesContext } from 'react-app';
import { Button } from 'react-bootstrap';
import { getCurrentUserId } from 'redux/selectors/users';

import SectionContent from '../section-content';
import { DisableProps } from './integrations';
import { config } from '../../../../../config/pendo.config.json';

const ZOOM_OAUTH_DISABLE_ARTICLE_LINK = 'https://help.novoed.com/hc/en-us/articles/360061031512';

type ZooomIntegrationProps = {
  onDisable?: (props: DisableProps) => void
  isLecturePage?: boolean
  isMeetingCreated?: boolean
  invalidSession?: boolean
  creatorFullName?: string
  authenticatedUser?: { email: string, id: number }
  integrationType?: string
};

const ZoomIntegration = (props: ZooomIntegrationProps) => {
  const { $uibModal, RailsRoutes, $location } = useContext(AngularServicesContext);
  const { injectServices } = useContext(AngularContext);

  const [InstitutionsManager] = injectServices(['InstitutionsManager']);

  const [hasIntegration, setHasIntegration] = useState(false);
  const [integrationType, setIntegrationType] = useState('');
  const [liveSessionSettings, setLiveSessionSettings] = useState(null);

  const currentUserId = useSelector(getCurrentUserId);
  const catalogId = useSelector((state) => state.app.currentCatalogId);

  const checkIntegrationType = (type) => liveSessionSettings?.source?.includes(type);
  const integrationSettings = liveSessionSettings?.[camelCase(integrationType)];
  // The InstitutionsManager may not be available when calling from lecture page, so using props
  const authenticatedUser = integrationSettings?.authenticatedUser || props.authenticatedUser;

  useEffect(() => {
    InstitutionsManager.getLiveSessionSettings().then((settings) => {
      setLiveSessionSettings(settings);
    });
  });

  useEffect(() => {
    if (!liveSessionSettings) {
      return;
    }
    const integrationTypes = InstitutionsManager.INTEGRATION_TYPES;

    const zoomAccountLevel = checkIntegrationType(integrationTypes.ZOOM_ACCOUNT_LEVEL);
    const zoomUserLevel = checkIntegrationType(integrationTypes.ZOOM_USER_LEVEL);

    if (props.integrationType) {
      setIntegrationType(props.integrationType);
    } else if (zoomAccountLevel) {
      setIntegrationType(integrationTypes.ZOOM_ACCOUNT_LEVEL);
    } else if (zoomUserLevel) {
      setIntegrationType(integrationTypes.ZOOM_USER_LEVEL);
    } else {
      setIntegrationType(null);
    }

    // Set hasIntegration based on if any integration type matches
    setHasIntegration(zoomAccountLevel || zoomUserLevel);
  }, [liveSessionSettings]);

  const configureZoom = () => {
    $uibModal.open({
      templateUrl: 'institutions/templates/configure-zoom-modal.html',
      controller: 'ConfigureZoomModalCtrl as vm',
      resolve: {
      },
    });
  };

  const disableZoom = () => {
    props.onDisable({
      action: () => InstitutionsManager.disableLiveSessionSettings(integrationType),
      onSuccess: () => {
        InstitutionsManager.institution.updateFromReact({
          liveSessionSettings: {
            apiKey: '',
            apiSecret: '',
            source: without(
              liveSessionSettings.source,
              integrationType,
            ),
          },
          zoomEnabled: false,
        });
        setHasIntegration(false);
      },
      successMessage: t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_ZOOM_SUCCESS(),
      title: t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_INTEGRATION(),
      bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_ZOOM_DESCRIPTION(),
      confirmDataQa: config.pendo.settings.zoom.disableConfirm,
      cancelDataQa: config.pendo.settings.zoom.disableCancel,
    });
  };

  const zoomDescription = integrationType === 'zoom_account_oauth'
    ? t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_ACCOUNT_OAUTH_DESCRIPTION(ZOOM_OAUTH_DISABLE_ARTICLE_LINK)
    : t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INTEGRATION_DESCRIPTION();

  const authenticateAccountZoomOauth = () => {
    if (integrationType === 'zoom_account_oauth') {
      window.location.href = RailsRoutes.accountZoomOauthUrl(InstitutionsManager.institution.id);
    }
    if (integrationType === 'zoom_oauth') {
      const refererUrl = `${$location.protocol()}://${$location.host()}/#!${$location.path()}${$location.hash()}`;
      window.location.href = RailsRoutes.zoomOAuthInstallationUrl(catalogId, '', refererUrl);
    }
  };

  const getZoomReAuthenticate = () => {
    const userFullName = props.creatorFullName;
    const userEmail = authenticatedUser?.accountEmail;
    const isCurrentUser = currentUserId === authenticatedUser?.id;
    const isZoomOAuth = integrationType === 'zoom_oauth';
    const isZoomAccountOAuth = integrationType === 'zoom_account_oauth';
    const showAdminViewMessage = props.isLecturePage;

    if (!showAdminViewMessage || props.invalidSession) {
      return null;
    }

    // Only required to authenticate button
    // 1. You are the meeting creator and the meeting is created
    // 2. Anyone can re-authenticate if the meeting has not been created
    const showReAuthButton = (isZoomOAuth && ((isCurrentUser) || (!isCurrentUser && !props.isMeetingCreated)))
      || (isZoomAccountOAuth && isCurrentUser);

    const getOAuthMessage = () => {
      if (integrationType === 'zoom_account_oauth') {
        return t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INVALID.ACCOUNT_OAUTH_MESSSAGE(userEmail);
      }
      return (!props.isMeetingCreated)
        ? t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INVALID.OAUTH_MESSAGE_CLONE_COURSE_ONLY(userFullName)
        : t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INVALID.OAUTH_MESSAGE(userFullName);
    };

    return (
      <div className='d-flex flex-column align-items-center justify-content-center border-top border-danger bg-gray-7 py-2'>
        {showAdminViewMessage && (
          <>
            <div className='text-small font-weight-bolder mb-2'>
              {t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INVALID.ADMIN_VIEW()}
            </div>
            <div className='text-small mb-2'>
              {getOAuthMessage()}
            </div>
          </>
        )}

        {showReAuthButton && (
          <Button variant='primary' onClick={authenticateAccountZoomOauth}>
            {t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INVALID.BUTTON()}
          </Button>
        )}
      </div>
    );
  };

  /**  Utilising the same component for reauthenticate and integartion,
   *  so using the prop, `isLecturePage` to differentiate from where it has been called
   */

  if (!props.isLecturePage) {
    return (
      <SectionContent
        header={t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INTEGRATION_TITLE()}
        description={[zoomDescription]}
        showCTAButton={!hasIntegration}
        ctaText={t.INSTITUTIONS.ADVANCED_SETTINGS.CONFIGURE()}
        onAction={() => (hasIntegration ? disableZoom() : configureZoom())}
        buttonVariant={hasIntegration ? 'secondary' : 'primary'}
        dataQa={config.pendo.settings.zoom.cta}
        extras={(
          hasIntegration
          && (
          <div className='w-100'>
            {integrationSettings?.authenticatedStatus === 'not_authenticated'
            && integrationType === 'zoom_account_oauth'
            && getZoomReAuthenticate()}
            <div className='button-bar mt-6'>
              <Button
                variant='secondary'
                onClick={disableZoom}
                data-qa={config.pendo.settings.zoom.cta}
              >
                {t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_ZOOM()}
              </Button>
            </div>
          </div>
          )
        )}
      />
    );
  }

  return getZoomReAuthenticate();
};

export default ZoomIntegration;
